<template>
  <Layout :tituloPagina="`Hotspots | Hotspots | ${modo == 'nuevo' ? 'Nuevo' : 'Edicion'}`">
    <div class="row">
      <div class="col-md-9">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{modo == 'nuevo' ? 'Nuevo' : 'Edicion'}} hotspot
              <small class="font-size-10">
                Aquí puedes {{modo == 'nuevo' ? 'registrar un nuevo ' : 'editar un'}} hotspot
              </small>
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Nombre del hotspot</label>
                <input
                  class="form-control"
                  type="text"
                  name="nombre"
                  ref="nombre"
                  v-model="hotspot.nombre"
                  placeholder="Ingrese un nombre"
                />
              </div>
              <div class="col-md-6">
                <label for="autenticacion">Tipo de autenticación</label>
                <input
                  type="text"
                  class="form-control fw-semibold"
                  id="autenticacion"
                  :value="tipo_autenticacion == 1 ? 'PIN' : 'Usuario y contraseña'"
                  readonly
                  disabled
                  :class="{
                    'text-warning' : tipo_autenticacion == 2,
                    'text-primary' : tipo_autenticacion == 1
                  }"
                />
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-4">
                <label>Seleccione el router donde se creara un hotspot</label>
                <div class="input-group">
                  <select
                    v-model="hotspot.id_router"
                    class="form-select"
                    name="router"
                    ref="router"
                    @change="
                      hotspot.id_lan = null,
                      cargarInterfacesLan()
                    "
                  >
                    <option :value="null">Seleccionar</option>
                    <option
                      v-for="router in routers"
                      :value="router.id"
                      :key="`router-${router.id}`"
                    >
                      {{ router.nombre }}
                    </option>
                  </select>
                  <button
                    class="btn btn-warning"
                    title="Editar router"
                    @click="editarRouter()"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                  </button>
                  <router-link
                    :to="{ name: 'nuevoRouter', query: { hotspot : true } }"
                    class="btn btn-success"
                    title="Nuevo router"
                  >
                    <i class="mdi mdi-plus-thick"></i>
                  </router-link>
                </div>
              </div>
              <div class="col-md-4">
                <label>Seleccione la interfaz LAN</label>
                <div class="input-group">
                  <select
                    v-model="hotspot.id_lan"
                    class="form-select"
                    name="lan"
                    ref="lan"
                    :disabled="hotspot.id_router == null"
                  >
                    <option :value="null">Seleccionar</option>
                    <option
                      v-for="lan in lans"
                      :value="lan.id"
                      :key="`lan-${lan.id}`"
                    >
                      {{ lan.descripcion }} {{ lan.id }}
                    </option>
                  </select>
                  <button
                    class="btn btn-warning"
                    title="Editar interfaz LAN"
                    @click="editarInterfazLAN()"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                  </button>
                  <button
                    class="btn btn-success"
                    title="Nuevo interfaz LAN"
                    @click="nuevaInterfazLAN()"
                  >
                    <i class="mdi mdi-plus-thick"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4">
                <label>Seleccione layout portal cautivo</label>
                <div class="input-group">
                  <select
                    v-model="hotspot.id_portal_cautivo"
                    class="form-select"
                    name="portalCautivo"
                    ref="portalCautivo"
                  >
                    <option :value="null">Seleccionar</option>
                    <option
                      v-for="portal in portales_cautivos"
                      :value="portal.id"
                      :key="`portal-${portal.id}`"
                    >
                      {{ portal.nombre }}
                    </option>
                  </select>
                  <button
                    class="btn btn-warning"
                    title="Editar layout portal cautivo"
                    @click="editarPortalCautivo()"
                  >
                    <i class="mdi mdi-content-save-edit-outline"></i>
                  </button>
                  <router-link
                    :to="{ name: 'nuevoPortalCautivoHotspot', query: { hotspot : true } }"
                    class="btn btn-success"
                    title="Nuevo layout portal cautivo"
                  >
                    <i class="mdi mdi-plus-thick"></i>
                  </router-link>
                </div>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-md-12">
                <label>Descripción</label>
                <textarea
                  class="form-control"
                  name="descripcion"
                  placeholder="Descripción del hotspot"
                  v-model="hotspot.descripcion"
                  rows="5"
                ></textarea>
              </div>
            </div>

            <div v-show="modo != 'nuevo'">
              <hr>
              <strong>Otras funciones del sistema</strong>&nbsp;
              <button
                class="btn btn-soft-dark btn-sm"
                @click="
                  opciones_sincronizacion_visibles = !opciones_sincronizacion_visibles
                "
              >
                <i
                  class="mdi"
                  :class="{
                    'mdi-chevron-down': !opciones_sincronizacion_visibles, 
                    'mdi-chevron-up': opciones_sincronizacion_visibles
                  }"
                ></i>
              </button>
              <br />
              <div v-show="opciones_sincronizacion_visibles">
                <button
                  class="btn btn-light bg-soft-dark btn-sm"
                  title="Fuerza la resincronización del hotspot"
                  @click="resincronizar()"
                  :disabled="hotspot.bs_sinc==false"
                >
                  <i class="mdi mdi-refresh"></i>
                  {{ hotspot.bs_sinc ? 'Resincronizar' : 'Sincronizando' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="atras()">
              <i class="mdi mdi-chevron-left"></i>
              Atrás
            </button>
  
            <button
              class="btn btn-success"
              @click="modo == 'nuevo' ? guardar() : actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import API from '@/API.js'
import RouterSrv from '@/services/RouterSrv.js'
import HotspotsSrv from '@/services/hotspot/HotspotsSrv.js'
import PortalCautivoSrv from '@/services/hotspot/PortalCautivoSrv.js'
import LanRouterSrv from '@/services/LanRouterSrv.js'

import Layout from "@/views/layouts/main"
export default {
  name: 'EdicionHotspot',
  components: { Layout },
  data() {
    return {
      modo: 'nuevo',
      hotspot: {
        id: null,
        nombre: sessionStorage.getItem('argusblack.hotspot.nombre') !== null ? sessionStorage.getItem('argusblack.hotspot.nombre') : '',
        descripcion: sessionStorage.getItem('argusblack.hotspot.descripcion') !== null ? sessionStorage.getItem('argusblack.hotspot.descipcion') : '',
        id_router: sessionStorage.getItem('argusblack.hotspot.id_router') !== undefined ? sessionStorage.getItem('argusblack.hotspot.id_router') : null,
        id_lan: sessionStorage.getItem('argusblack.hotspot.id_lan') !== undefined ? sessionStorage.getItem('argusblack.hotspot.id_lan') : null,
        id_portal_cautivo: sessionStorage.getItem('argusblack.hotspot.id_portal_cautivo') !== undefined ? sessionStorage.getItem('argusblack.hotspot.id_portal_cautivo') : null
      },
      opciones_sincronizacion_visibles: 0,
      tipo_autenticacion: 1,      // 1 = PIN, 2 = Usuario y contraseña
      routers: [],
      lans:[],
      portales_cautivos: [],
      bandera_spinner: false
    }
  },
  watch: {
    'hotspot.nombre': function(newNombre, oldNombre){
      var self = this
      
      if(newNombre == oldNombre) return
      if(self.modo == 'edicion') return

      sessionStorage.setItem('argusblack.hotspot.nombre', self.hotspot.nombre)
    },
    'hotspot.descripcion': function(newDesc, oldDesc) {
      var self = this
      
      if(newDesc == oldDesc) return
      if(self.modo == 'edicion') return

      sessionStorage.setItem('argusblack.hotspot.descripcion', self.hotspot.descripcion)
    },
    'hotspot.id_router': function(newRout, oldRout){
      var self = this
      
      if(newRout == oldRout) return
      if(self.modo == 'edicion') return

      sessionStorage.setItem('argusblack.hotspot.id_router', self.hotspot.id_router)
    },
    'hotspot.id_lan': function(newLan, oldLan){
      var self = this

      if(newLan == oldLan) return
      if(self.modo == 'edicion') return

      sessionStorage.setItem('argusblack.hotspot.id_lan', self.hotspot.id_lan)
    },
    'hotspot.id_portal_cautivo': function (newPortal, oldPortal){
      var self = this

      self.obtenerTipoAutenticacion()

      if(newPortal == oldPortal) return
      if(self.modo == 'edicion') return

      sessionStorage.setItem('argusblack.hotspot.id_portal_cautivo', self.hotspot.id_portal_cautivo)
    }
  },
  created: function() {
    var self = this

    // Detección de modo
    if (this.$route.path.indexOf('nuevo') >= 0) this.modo = 'nuevo'
    else this.modo = 'edicion'

    switch(self.modo) {
      case 'nuevo':
        self.cargarRouters()
        self.cargarPortalesCautivos()

        if(self.hotspot.id_lan != null && self.hotspot.id_lan != 'null' || self.hotspot.id_router != null && self.hotspot.id_router != 'null')  self.cargarInterfacesLan()
      break;
      case 'edicion':
        self.cargarHotspot()
        self.cargarRouters()
        self.cargarPortalesCautivos()
      break;
    }
  },
  methods: {
    actualizar: function() {
      var self = this

      self.bandera_spinner = true

      if (!self.verificarDatosCorrectos()) return

      HotspotsSrv.actualizar(self.hotspot).then(response => {
        iu.msg.success('Se actualizo correctamente el hotspot')
      }).catch(error =>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar el hotspot'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    atras: function() {
      this.$router.go(-1)
    },
    cargarInterfacesLan: function() {
      var self = this, params = { sin_usar_por_hotspots : true }

      var idRouter = self.hotspot.id_router

      self.lans = []

      if(idRouter == null) return iu.msg.warning('Se debe de seleccionar una router para poder cargar las interfaces LAN')

      LanRouterSrv.lansJSON(idRouter, params).then(response => {
        self.lans = response.data

        if(self.hotspot.id_lan != null) self.cargarInterfazLanFaltante()

      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se puedieron cargar las interfaces LAN'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarInterfazLanFaltante: function() {
      var self = this

      LanRouterSrv.lanJSON(self.hotspot.id_lan).then(response => {
        self.lans.push(response.data)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar las interfaz LAN'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarHotspot: function() {
      var self = this,
          idHotspot = self.$route.params.id

      HotspotsSrv.hotspotJSON(idHotspot).then(response => {
        self.hotspot = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el hotspot'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally (final => {
        self.cargarInterfacesLan()
      })
    },
    cargarPortalesCautivos: function() {
      var self = this
      
      PortalCautivoSrv.portalesJSON().then(response => {
        self.portales_cautivos = response.data

        self.obtenerTipoAutenticacion()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los portales cautivos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarRouters: function() {
      var self = this
      
      RouterSrv.routersJSON().then(response => {
        self.routers = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cerrar: function() {
      this.atras()
    },
    editarInterfazLAN: function () {
      var self = this

      if(self.hotspot.id_router == null){
        iu.msg.warning('Para editar una interfaz LAN se debe seleccionar primero el router')
        self.$refs.router.focus()
        return
      }

      if(self.hotspot.id_lan == null) {
        iu.msg.warning('Para editar una interfaz LAN se debe de seleccionar una interfaz LAN primero')
        self.$refs.lan.focus()
        return
      }

      self.$router.push({
        name: 'edicionLanRouter',
        params: {
          id: self.hotspot.id_router,
          id_lan: self.hotspot.id_lan
        }
      })
    },
    editarPortalCautivo: function() {
      var self = this

      if(self.hotspot.id_portal_cautivo == null) {
        iu.msg.warning('Para editar un layout de portal cautivo se debe de seleccionar primero el layout de portal cautivo')
        self.$refs.portalCautivo.focus()
        return
      }

      self.$router.push({
        name: 'edicionPortalCautivoHotspot',
        params: { 
          id: self.hotspot.id_portal_cautivo
        }
      })
    },
    editarRouter: function () {
      var self = this

      if(self.hotspot.id_router == null) {
        iu.msg.warning('Para editar un router se debe se seleccionar el router a editar')
        self.$refs.router.focus()
        return
      }

      self.$router.push({
        name: 'edicionRouter',
        params: {
          id: self.hotspot.id_router
        }
      })
    },
    guardar: function() {
      var self = this

      self.bandera_spinner = true

      if (!self.verificarDatosCorrectos()) return

      HotspotsSrv.guardar(self.hotspot).then(response => {
        let idHotspot = response.data
        iu.msg.success('Hotspot registrado')
        self.$router.replace({
          name: 'edicionHotspot',
          params: {
            id: idHotspot
          }
        })

        self.modo = 'edicion'
        self.hotspot.id = idHotspot
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo registrar el hotspot'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    },
    nuevaInterfazLAN: function () {
      var self = this

      if(self.hotspot.id_router == null){
        iu.msg.warning('Para registrar una nueva interfaz a un router se debe seleccionar primero el router')
        self.$refs.router.focus()
        return
      }

      self.$router.push({
        name: 'nuevaLanRouter',
        params: {
          id: self.hotspot.id_router,
          hotspot: true
        }
      })
    },
    obtenerTipoAutenticacion: function () {
      var self = this

      self.tipo_autenticacion = 1

      var index = self.portales_cautivos.findIndex(function (portal) {
        return portal.id == self.hotspot.id_portal_cautivo
      })

      if(index == -1) return
      
      self.tipo_autenticacion = self.portales_cautivos[index].tipo_autenticacion
    },
    resincronizar() {
      let self = this

      self.hotspot.bs_sinc = false
      HotspotsSrv.resincronizar(self.hotspot.id).then(response => {
        self.hotspot.bs_sinc = true
        iu.msg.success('Solicitud de resincronización realizada correctamente.')
      }).catch(error => {
        self.hotspot.bs_sinc = false
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo completar la solicitud de resincronizacion'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    verificarDatosCorrectos(){
      var self = this,
          hotspot = self.hotspot

      if(hotspot.nombre == '') {
        iu.msg.warning('Es necesario ingresar un nombre')
        self.$refs.nombre.focus()
        self.bandera_spinner = false
        return false
      }

      if( hotspot.id_router == null ){
        iu.msg.warning('Es necesario seleccionar un router')
        self.$refs.router.focus()
        self.bandera_spinner = false
        return false
      }
      
      if( hotspot.id_lan == null ){
        iu.msg.warning('Es necesario seleccionar un interfaz LAN')
        self.$refs.lan.focus()
        self.bandera_spinner = false
        return false
      }

      if( hotspot.id_portal_cautivo == null ){
        iu.msg.warning('Es necesario seleccionar un layout de portal cautivo')
        self.$refs.portalCautivo.focus()
        self.bandera_spinner = false
        return false
      }

      return true
    }
  }
}
</script>